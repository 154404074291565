/**
 * Swap page
 */
.swapbox {
  height: 100%; }

.row-swap-amount {
  height: 25px; }

.swap-card.card {
  border-radius: 25px; }

.field-swap-amount {
  width: 50%; }

.swapDownArrowIcon {
  color: #B3498E;
  height: 30px;
  width: 42px; }

.swapped-amount {
  margin-top: 15px;
  font-family: Silkscreen;
  font-size: 8em; }

.input-amount-row {
  width: 90%;
  /*max-width: 200px;*/ }

/* Small screen */
@media only screen and (max-width: 580px) {
  .input-amount-row {
    width: 100%; }
  .field-swap-amount {
    width: 100%;
    margin-left: 0%; }
  .swapcard-col {
    margin-bottom: 15px; } }
