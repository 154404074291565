.explanationbox.liquiditybox.blue, .explanationbox.liquiditybox.gold, .explanationbox.liquiditybox.nftbox {
  height: 100%;
  min-height: 100px; }

.explanationbox.liquiditybox.gold .daily-rewards {
  padding-top: 15px; }

.explanationbox.liquiditybox.gold .card-text {
  padding: 15px 0px 25px 0px; }

.explanationbox.liquiditybox.gold .card-subtitle {
  font-size: 3rem; }

.crown-icon {
  padding-top: 15px;
  color: #b47e11; }

.claim-button.btn-primary {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.3), inset 0 -2px 5px 1px rgba(139, 66, 8, 0.8), inset 0 -1px 1px 3px rgba(250, 227, 133, 0.8);
  background-image: linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07);
  border: 1px solid #a55d07;
  color: #522205;
  text-shadow: 0 2px 2px #fae385;
  cursor: pointer;
  transition: all .2s ease-in-out;
  background-size: 100% 100%;
  background-position: center;
  font-size: 2em;
  font-family: Silkscreen;
  font-weight: bold;
  margin-bottom: 15px;
  border-radius: 11px; }

.pinkNFTBonus {
  color: #B3498E; }

/* Small screen */
@media only screen and (max-width: 580px) {
  .explanationbox.liquiditybox.blue, .explanationbox.liquiditybox.gold {
    min-height: 250px !important; }
  .crown-icon {
    padding-top: 2px; }
  .crown-icon.right-crown {
    visibility: hidden;
    display: none; } }
