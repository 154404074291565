/* Small screen */
@media only screen and (max-width: 580px) {
  .actionbox {
    margin-bottom: 15px; }
  .subpage-header-title {
    text-align: center !important;
    height: 24px;
    line-height: 24px; }
  .pink-button.btn.btn-primary {
    width: 100%;
    margin-top: 15px; } }
