.lgebox {
  height: 100%; }

.row-lge-amount.row {
  width: 100%;
  margin: 0 auto; }

.totalSwapped {
  font-family: SilkScreen;
  font-size: 1.5rem;
  text-align: center; }
