.bid-mandatory-container {
  height: 100%;
  border: 1px dashed #003366;
  margin-bottom: 5px;
  box-sizing: border-box;
  padding: 15px; }

.bid-chain-container {
  height: 50vh;
  border: 1px dashed #003366; }

.bid-form {
  width: 100%; }

.add-chain-box {
  height: 50%;
  width: 50%;
  max-height: 150px;
  max-width: 600px;
  /*border: 1px dashed grey;*/ }

.popover-add-chain-list {
  list-style-type: none; }

.pink-add-chain-button {
  width: 85px;
  font-size: 12px;
  padding: 0px;
  margin: 0px; }

.choose-button {
  max-width: 300px; }

.proposal-tab-item.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #003366;
  color: white;
  border-radius: 11px;
  padding: 10px; }

.choose-button button {
  border-radius: 11px;
  background-color: #CC62A7;
  border: 1px solid #B3498E; }

.bid-description-border {
  border: 1px dashed #003366; }

.bid-tab-border {
  border-left: 1px dashed #003366;
  border-top: 1px dashed #003366;
  border-bottom: 1px dashed #003366;
  padding: 10px; }

.function-overlay {
  margin-top: 15px;
  margin-left: 15px; }

.bid-content {
  margin: 10px; }

/* TINY FIX FOR TYPEAHEAD */
.rbt-aux {
  width: 90px !important; }
