.nftbox-logo-image.top {
  width: 319px;
  height: 450px; }

.account-tier {
  color: #EEC900;
  font-weight: 700;
  text-align: center;
  font-size: 2em;
  font-family: SilkScreen;
  padding-top: 15px;
  position: relative;
  transform: translate(-50%, -50%);
  top: 100%;
  left: 50%;
  letter-spacing: 5px;
  font-weight: bold; }

.account-tier.common {
  background-image: linear-gradient(to right, #2eff05 0, #168d0c 22%, #92dbab 45%, #53b96a 50%, #08572f 55%, #518f56 78%, #477c45 100%);
  color: transparent;
  -webkit-background-clip: text; }

.account-tier.rare {
  background-image: linear-gradient(to right, #b538b9 0, #af5ec4 22%, #8d5e92 45%, #8b2c85 50%, #c677d6 55%, #9150ca 78%, #cb02f3 100%);
  color: transparent;
  -webkit-background-clip: text; }

.account-tier.epic {
  background-image: linear-gradient(to right, #ff000d 0, #e67d86 22%, #835050 45%, #4b0e16 50%, #69060b 55%, #f10f2e 78%, #ff353f 100%);
  color: transparent;
  -webkit-background-clip: text; }

.account-tier.legendary {
  background-image: linear-gradient(to right, #c9ad0f 0, #f5d15a 22%, #bccf0e 45%, #eedf0a 50%, #b19129 55%, #ffd900 78%, #b6b079 100%);
  color: transparent;
  -webkit-background-clip: text; }

.card-img-top.nftbox-logo-image.top {
  margin-bottom: 25px; }

.card-img-top.nftbox-logo-image.top.common {
  box-shadow: 1px 4px 18px 8px #31885a; }

.card-img-top.nftbox-logo-image.top.rare {
  box-shadow: 1px 4px 18px 8px #962f96; }

.card-img-top.nftbox-logo-image.top.epic {
  box-shadow: 1px 4px 18px 8px #f24021; }

.card-img-top.nftbox-logo-image.top.legendary {
  box-shadow: 1px 4px 18px 8px #e2d922; }

.tier-description {
  margin-bottom: 25px; }

.placeholder-icon {
  margin: 25px 0px 15px 0px; }

/*** 
   * Mobile
   **/
@media only screen and (max-width: 580px) {
  .actionbox {
    margin-top: 15px !important; }
  .account-tier {
    font-size: 1em !important;
    padding-top: 10px !important; }
  .card-img-top.nftbox-logo-image.top {
    height: 80% !important;
    width: 80% !important; } }
