@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap");
Body {
  background-color: #fdfafa;
  box-sizing: border-box; }

.application-body {
  min-height: 70vh; }

.footer {
  /* 
  position: absolute;
  bottom: 10px;
  */
  /*margin-top: 80vh; /* minus 20vh from header*/
  margin-top: 2vh;
  height: 50px;
  text-align: center;
  left: 0;
  right: 0; }

.links-box {
  margin-left: 5vh; }

.links-box a {
  font-weight: 600;
  text-decoration: none;
  color: #B3498E; }

/** Footer address bar **/
.addr-name {
  display: inline-block; }

.addr-pink {
  background-color: #B3498E;
  border-radius: 5px;
  /* width: 365px; */
  width: 100%;
  display: block;
  cursor: pointer;
  color: white;
  margin-bottom: 5px; }

/** Footer social icons **/
.social-box {
  margin: 0 5vh 1vh 0;
  text-align: center; }

.social-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 10px;
  background-color: gray;
  border-radius: 50%;
  cursor: pointer; }

.git {
  background-image: url("../assets/github.png");
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat; }

.twit {
  width: 40px;
  height: 40px;
  background-image: url("../assets/twitter.png");
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat; }

.tele {
  background-image: url("../assets/telegram.png");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat; }

/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/
/** FORM INPUTS **/
.input-amount {
  width: 98%;
  height: 3rem;
  outline: none;
  font-size: 1em;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding-left: 10px; }

/** max container **/
.max-container {
  text-align: right;
  padding-right: 1vh;
  padding-top: 2vh;
  padding-bottom: 1vh; }

/** BUTTON & LINKS **/
.button {
  padding: 10px;
  margin: 0 auto;
  background-color: #fca3dc;
  color: #282c34;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border-radius: 6px;
  cursor: pointer; }

button.as-link {
  color: #B3498E;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline; }

.pink-link {
  color: #B3498E;
  font-size: 1em;
  cursor: pointer;
  text-decoration: underline; }

.menu {
  color: #ff95da;
  font-weight: 700;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none; }

.menu:hover {
  color: white; }

/** TEXT & CONTENT **/
.pink-text {
  color: #B3498E; }

.darkpink-text {
  color: #9e3674; }

.title {
  color: black;
  font-weight: 700;
  font-family: Silkscreen;
  text-align: center;
  font-size: 3em; }

.card-horizontal {
  display: flex;
  flex: 1 1 auto; }

/** VARIOUS **/
/** Remove input spinners for number fields **/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/** Alignment **/
.align-left {
  float: left; }

.align-right {
  float: right; }

.clear {
  clear: both; }

/**
  * CARDS ON HOMEPAGE / STAKING
  */
.actionbox {
  border: 0;
  background-color: transparent;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.19);
  transition: all .5s;
  border-radius: 30px; }

.actionbox.disabled {
  box-shadow: 1px 4px 18px 1px #636363;
  background-color: #f2f2f2;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23696969' fill-opacity='0.25' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E"); }

.actionbox-logo-image {
  height: 4em;
  margin: 20px 0px 0px 15px; }

.actionbox-logo-image.top {
  height: 64px;
  width: 64px; }

.actionbox-footer {
  background-color: transparent; }

.actionbox-body {
  text-align: left; }

.actionbox-body .card-text {
  margin-bottom: 0px;
  font-size: .8em; }

.actionbox-body .card-title {
  font-family: SilkScreen;
  font-size: 1.2em;
  margin: 0; }

.actionbox-body .card-title.exact-toggle {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: .8em; }

.actionbox-body .card-subtitle {
  font-family: SilkScreen;
  font-size: 1em;
  margin: 0; }

.actionbox-body .actionbox-button {
  margin-top: 10px;
  margin-bottom: 20px; }

/** Explanation box **/
.explanationbox {
  min-height: 340px;
  height: 55vh;
  border: 0;
  background-color: transparent;
  box-shadow: 1px 4px 18px 0 rgba(0, 0, 0, 0.19);
  transition: all .5s; }

.explanationbox-header {
  height: 20%; }

.explanationbox-body {
  margin-top: 3%;
  height: 60%; }

.explanationbox-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 20%; }

.explanationbox-header .help-icon {
  padding-top: 2px;
  padding-right: 2px; }

/** Colors for the explanation box **/
.explanationbox.purple {
  box-shadow: 1px 4px 18px 1px #fe77ff;
  background-color: rgba(254, 119, 255, 0.15); }

.help-icon {
  color: #013366; }

.explanationbox.yellow {
  box-shadow: 1px 4px 18px 1px #ead186;
  background-color: rgba(234, 209, 134, 0.15); }

.explanationbox.blue {
  box-shadow: 1px 4px 18px 1px #311ac5;
  background-color: rgba(49, 26, 197, 0.15); }

.explanationbox.gold {
  box-shadow: 1px 4px 18px 1px goldenrod;
  background-color: rgba(240, 230, 140, 0.15); }

.explanationbox.green {
  box-shadow: 1px 4px 18px 1px #dcfbd2;
  background-color: rgba(220, 251, 210, 0.15); }

.explanationbox.disabled {
  box-shadow: 1px 4px 18px 1px #636363;
  background-color: #f2f2f2;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23696969' fill-opacity='0.25' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E"); }

.disabled .step-wrapper-circle {
  background-color: #f2f2f2 !important;
  color: grey !important; }

.step-wrapper > .purple {
  background-color: #FF8FFF;
  border: 1px solid #FF8FFF;
  color: white; }

.step-wrapper > .yellow {
  background-color: #ead186;
  border: 1px solid #ead186;
  color: white; }

.step-wrapper > .blue {
  background-color: #988bda;
  border: 1px solid #988bda;
  color: white; }

/***
* Card makeup
*/
.explanationbox {
  /*height: 35vh;*/ }

.explanationbox .card-title {
  font-family: SilkScreen;
  margin: 5px 0px 0px 0px;
  font-size: 1.2em; }

.explanationbox .card-text {
  font-size: 1rem; }

.explanationbox-logo-image {
  width: 15%;
  margin: 25px 0px 0px 15px; }

.explanationbox-logo-image.top {
  height: 24px;
  width: 24px; }

.explanationbox-footer {
  background-color: transparent; }

.explanationbox .button-row {
  margin: 0; }

/*
.explanationbox-body {
  text-align: left;
  height: 30vh;
}

.explanationbox-body .card-text{
  margin-bottom: 0px;
  font-size: 1em;
}

.explanationbox-body .explanationbox-button {
  margin-top: 5vh
}
*/
.explanationbox .step-wrapper {
  padding-left: 0px;
  height: 24px;
  font-size: 3em;
  font-family: Silkscreen; }

.explanationbox .step-wrapper-circle {
  align-self: flex-end;
  height: 48px;
  width: 48px;
  line-height: 48px;
  position: relative;
  top: -18px;
  left: -18px;
  border-radius: 50%;
  border: 1px solid #000;
  font-size: 1em;
  font-family: Silkscreen;
  text-align: center; }

.explanationbox .subtext {
  font-size: .8em;
  margin: 0; }

.explanationbox .balance {
  font-family: Silkscreen; }

.explanationbox .buttonRow {
  margin: 25px 0px 15px 0px; }

/** Liquidity provider box **/
.liquiditybox {
  height: 100%; }

.liquiditybox .card-text {
  font-size: .9em;
  padding-top: 5px;
  padding-bottom: 15px; }

.liquiditybox .card-subtitle {
  font-size: 1em;
  font-family: Silkscreen; }

/**
* Liquidity row stuff
*/
.liquidity-row .card {
  border-radius: 25px !important;
  box-shadow: 1px 2px 12px 1px #636363;
  background-color: transparant; }

.liquidity-title {
  font-family: Silkscreen;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.liquidity-button {
  float: right; }

.liquidity-more-info-button {
  border: 0;
  text-decoration: underline;
  background-color: transparent;
  color: #B3498E; }

.liquidity-details-title {
  float: left; }

.liquidity-details-figures {
  float: right; }

.liquidity-more-info-button .hidden {
  display: hidden;
  visibility: none; }

/** Learn more **/
.learn-more {
  margin-top: 15vh;
  justify-content: center;
  align-items: center;
  text-align: center; }

.header {
  height: 20vh;
  background: url("../assets/logos/banner.gif") no-repeat;
  background-size: cover;
  /*margin-bottom: 4vh;*/
  width: 100%;
  margin: 0; }

.ticker {
  margin-bottom: 4vh;
  font-size: 12px;
  font-family: Silkscreen;
  color: #ffffff;
  background-color: #B3498E;
  width: 100%; }

.pink-button {
  border-radius: 11px;
  background-color: #CC62A7;
  border: 1px solid #B3498E; }

.darkblue-button {
  border-radius: 11px;
  background-color: #003366;
  border: 1px solid #01284f; }

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #003366;
  border-color: #003366; }

.btn.btn-primary[disabled] {
  background-color: #a9a9a9;
  border-color: #a9a9a9; }

.brown-button {
  border-radius: 11px;
  background-color: #8B4513;
  border: 1px solid #722C00; }

.router-link {
  color: inherit;
  text-decoration: none; }

.router-link:hover {
  color: inherit;
  text-decoration: none; }

.router-link:hover .card {
  background-color: #003366;
  color: white; }

.subpage-headder-image {
  height: 48px;
  margin: 0;
  padding: 0; }

.subpage-header-title {
  font-family: "Silkscreen";
  font-weight: 900;
  color: black;
  position: relative;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  margin-left: 3px;
  text-align: left;
  font-size: 30px; }

.subpage-header-subtext {
  font-size: .9em; }

.header-image img {
  height: 96px;
  margin-bottom: 2vh; }

/**
  * Hacks
  */
.modal-backdrop {
  z-index: 100 !important; }

.modal-content {
  margin: 2px auto;
  z-index: 110 !important; }

.modal-box {
  background-color: transparent; }

.modal-box .modal-content {
  border-radius: 25px;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.19); }

.modal-box-title {
  font-family: Silkscreen;
  font-size: 1em; }

.modal-box .modal-header {
  border-bottom: 0px; }

/**
  * Web3-Modal css hacks
  */
.web3modal-provider-name {
  /** z-index: 10; **/
  font-family: Silkscreen; }

/***
 SWAP announcement homepage
 */
.purple-box {
  border: 2px dotted #000000;
  border-radius: 25px;
  background: #8ee77d; }

/**** Some optimizations for mobile **/
@media only screen and (max-width: 900px) {
  .Options-box {
    width: unset;
    margin-top: 10%; }
  .Option {
    width: 238px;
    height: 110px;
    /* background-color: gray; */
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.19);
    /* display: inline-block; */
    cursor: pointer;
    transition: all .5s;
    border-radius: 30px;
    background-color: white; }
  .Option:hover {
    width: 255px;
    height: 125px; }
  .Option:last-child {
    margin-left: unset; }
  .stake {
    position: relative;
    margin: 0 auto;
    margin-right: 0;
    margin-bottom: 10px; }
  .pumped {
    position: relative;
    margin: 0 auto;
    margin-right: 0;
    margin-bottom: 10px; } }

@media only screen and (max-width: 580px) {
  .top-box-container {
    display: none; }
  .address {
    position: unset; } }
