.paddingleft {
  margin-left: 5px; }

/* Small screen */
@media only screen and (max-width: 580px) {
  /** Explanation box **/
  .explanationbox {
    min-height: 530px !important;
    height: 100% !important;
    border: 0;
    background-color: transparent;
    box-shadow: 1px 4px 18px 0 rgba(0, 0, 0, 0.19);
    transition: all .5s;
    margin-bottom: 35px; }
  .explanationbox-header {
    height: 10% !important; }
  .explanationbox-body {
    margin-top: 3%;
    height: 30% !important; }
  .explanationbox-footer {
    position: absolute;
    bottom: 15px !important;
    width: 100%;
    height: 40% !important; }
  .explanationbox .button-row {
    margin: 0 auto !important; }
  .explanationbox-header .help-icon {
    padding-top: 2px;
    padding-right: 2px; }
  .arrow-right {
    visibility: hidden;
    display: none; } }
