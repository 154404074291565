/** Logo used in Header.js**/
.logo-container {
  text-align: right;
  position: relative;
  display: block;
  width: 90%;
  text-align: left;
  font-size: 30px;
  height: 100%; }

.logo-text {
  font-family: "Silkscreen";
  font-weight: 900;
  color: white;
  font-size: 2rem;
  position: relative;
  width: 20vw;
  margin-left: 3px;
  text-align: left;
  display: inline-block; }

.logo-image {
  height: 10vh; }

.pink-menu-button.btn.btn-primary {
  color: white;
  border-radius: 11px;
  background-color: #CC62A7;
  border: 1px solid #B3498E; }

/* Small screen */
@media only screen and (max-width: 580px) {
  .logo-text {
    font-size: 1.2rem; }
  .navbar-nav {
    background: #fff;
    z-index: 99999; }
  .ticker {
    display: none;
    visibility: hidden; } }
