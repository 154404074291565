.governancevotingbox {
  min-height: 300px; }

.governancevotingbox .card-text {
  min-height: 200px; }

.vote-text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  color: #B3498E; }
