.addresses-tooltip .tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #B3498E;
  border-radius: 11px; }

.addresses-tooltip .tooltip-inner a {
  color: white; }

.addressesCol {
  text-align: left !important; }

.social-box {
  text-align: right !important; }

/* Small screen */
@media only screen and (max-width: 580px) {
  .footer {
    display: flex;
    align-items: center;
    justify-content: center; }
  .footer .pink-button.btn.btn-primary {
    width: 50%;
    font-size: 10px; }
  .addressesCol {
    text-align: center !important; }
  .social-box {
    text-align: center !important;
    margin: 15px 0px 0px 0px !important; } }
